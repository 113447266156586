import check_icon from "../assets/images/circle_check.svg";
import college_logo from "../assets/images/mycollege_cost_logo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player/youtube";
import { CustomTab } from "./StudentParents";
import arrow_left from "../assets/images/silder_arrow_left.svg";
import arrow_right from "../assets/images/silder_arrow_right.svg";
import { useState } from "react";
import { Carousel } from "flowbite-react";
import gowen_photo from "../assets/images/gowen_photo.png";
import dana_photo from "../assets/images/dana_photo.png";
import charles_photo from "../assets/images/charles_photo.png";
import { QItem } from "./FAQ";

type Props = React.HtmlHTMLAttributes<HTMLDivElement> & {
  url: string;
};
export const VideoBox = (props: Props) => {
  const { url, className } = props;
  return (
    <div className={className}>
      <div className={`player-wrapper rounded-lg max-md:hidden`}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          loop={false}
          controls={true}
          className={"react-player rounded-md"}
        />
      </div>
      <div className={`player-wrapper rounded-lg md:hidden`}>
        <ReactPlayer
          url={url}
          width="100%"
          height={220}
          loop={false}
          controls={true}
          className={"react-player rounded-md"}
        />
      </div>
    </div>
  );
};
type IProps = {
  title: string;
};
type ItemProps = {
  title: string;
  name: string;
  desc: string;
  src: string;
};
const ItemLineBox = ({ title }: IProps) => {
  return (
    <h6 className="text-[#646464] font-light flex items-start space-x-1">
      <img src={check_icon} alt="" />
      <span>{title}</span>
    </h6>
  );
};
const ItemSrcBox = ({ src, title, desc, name }: ItemProps) => {
  return (
    <div className="xl:w-[916px] md:w-[700px] slider_md_wd p-6 border border-[#3FC6AD] rounded-lg mx-auto font-opensans bg-white max-md:pb-14">
      <div className="">
        <div>
          <img src={src} alt="" className="mx-auto" />
        </div>
        <h6
          className="text-center text-[#3FC6AD] font-bold"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      <div className="space-y-4">
        <h6 className="text-center text-[#2B574F] font-semibold xl:text-xl">
          {name}
        </h6>
        <p className="font-light text-center">{desc}</p>
      </div>
    </div>
  );
};
const ItemSrcBoxMobile = ({ src, title, desc }: ItemProps) => {
  return (
    <div>
      <div className="md:w-[416px] grid font-work_sans mx-auto">
        <div className="grid h-[104px]">
          <div>
            <img src={src} alt="" className="mx-auto" />
          </div>
          <h6 className="text-[#2B574F] font-extrabold text-xl text-center">
            {title}
          </h6>
        </div>
        <p className="font-light text-center text-[14px]">{desc}</p>
      </div>
    </div>
  );
};
const StudentRequirements = () => {
  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        Student requirements
      </h6>
      <ul className="space-y-2 font-light">
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />{" "}
          <span>
            Wants to enroll in a four-year college or university in the Fall
            this year?
          </span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />{" "}
          <span>
            Plan to enroll as a Full-Time Freshman or Transfer Student?
          </span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />{" "}
          <span>
            Will complete and submit the Free Application for Federal Student
            Aid (FAFSA).
          </span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />{" "}
          <span>
            Will be dependent on a parent or guardian for financial support.
          </span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />{" "}
          <span>
            Are open to sharing selected information from the FAFSA on the
            MyCollegeCosts tool.
          </span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />{" "}
          <span>Can complete a brief demographic form.</span>
        </li>
      </ul>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">Interested about MyCollegeCosts?</h6>
        <div>
          <button className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]">
            Contact us
          </button>
        </div>
      </div>
      <p className="italic font-light ">
        The security of your information is important to us! MyCollegeCosts.ai
        is a web-based secure browser best used on a mobile device. The
        information provided can only be released by the student to selected
        colleges and universities.
      </p>
    </div>
  );
};
const InformationStudentsNeed = () => {
  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        Information students need
      </h6>
      <p className="text-[#646464] font-light">
        <strong>
          Does an acceptance decision really matter if the student cannot afford
          to attend?
        </strong>
        <br />
        You know that no matter when a student completes the FAFSA, most
        colleges will NOT provide students a financial aid package until they
        have been accepted for admission. With MyCollegeCosts students will
        learn how much estimated aid they need to meet their financial need no
        matter what their admission status may be. This means that students can
        make important decisions about affordability before they complete the
        application process! <br />
        <br />
        Knowing how much financial aid a student is likely to need also allows
        students and families to begin financial planning for college before
        they are faced with state or institutional deadlines. This allows them
        to explore the availability of outside scholarships and grants. It
        encourages them to decide how and when to apply for alternative forms of
        aid. And if they choose, students can plan to use summer earnings to
        meet some of their educational expenses. The earlier students know the
        amount of financial assistance they need to attend your college, the
        more time they have to plan their financial future. 
      </p>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">Interested about MyCollegeCosts?</h6>
        <div>
          <button className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]">
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
};
const HowMyCollegeCostsHelps = () => {
  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        How MyCollegeCosts helps students
      </h6>
      <ul className=" list-disc font-light text-[#646464] ps-4">
        <li>
          Learn what the direct costs of attending the colleges listed on the
          FAFSA will be.
        </li>
        <li>
          See how much estimated financial assistance your students need to pay
          for those direct costs.
        </li>
        <li>
          Understand how much of that financial assistance is FREE and how much
          they will need to pay back or pay out-of-pocket.
        </li>
        <li>
          Review the expected GAP between the aid they are likely to be offered
          and how much they need.
        </li>
        <li>
          Engage in the important process of financial planning for college.
        </li>
        <li>Avoid insurmountable debt related to college enrollment.</li>
        <li>
          Receive this information without having to wait for an admission
          decision.
        </li>
        <li>
          Access recommended email and letter text to request the amount of aid
          they need from the colleges they are considering.
        </li>
        <li>
          Know that the college they want to attend is one they can afford to
          attend.
        </li>
      </ul>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">Interested about MyCollegeCosts?</h6>
        <div>
          <button className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]">
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
};
const CollegeBenefits = () => {
  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        College benefits
      </h6>
      <div className="space-y-4 font-light">
        <p>
          You will know how much estimated financial aid students will need to
          pay for the direct cost of attending your institution.
        </p>
        <p>
          As a result, you know how much institutional aid students will need to
          attend.
        </p>
        <p>
          Both you and the student will receive this information early in the
          admission cycle.
        </p>
        <p>
          The earlier the student and family understand the amount of financial
          aid they actually require to enroll, the more time they have to
          explore outside and alternative sources of aid.
        </p>
        <p>
          In order to receive an OFFICIAL financial aid award from your
          institution, students will be encouraged to submit their ISIR to
          you. MyCollegeCosts provides estimated awards based upon a variety of
          sources.
        </p>
        <p>
          Conversations with students and families can be far more relational
          when anxiety about cost is reduced or removed.
        </p>
        <p>
          “Better Fit” decisions are possible when cost related concerns are not
          a central issue in the decision-making process.
        </p>
        <p>
          Last minute deposit withdrawals are reduced when students and families
          are not surprised when the amount billed is higher than anticipated.
        </p>
      </div>

      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">Interested about MyCollegeCosts?</h6>
        <div>
          <button className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]">
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
};
const MakingTheirCase = () => {
  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        Making their case
      </h6>
      <p className="text-[#646464] font-light">
        Students are able to make better informed requests for additional aid if
        their financial aid does not meet their need (or expectations). Allowing
        for more relational and less confrontational conversation, this
        proactive step allows you to respond point-by-point to their concerns.{" "}
        <br />
        <br />
        How students make the request to close any gap in their financial aid
        has an important impact upon the likelihood that their request will be
        granted. That’s why MyCollegeCosts provides students example text to use
        when they reach out to your Director of Financial Aid or your Admissions
        counselors! View these requests as an important opportunity for
        conversation with your students.
        <br />
        <br />
        By submitting MyCollegeCosts results to each college a student may be
        considering, the student has an opportunity to actually compare your
        college to other colleges they are considering. 
      </p>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">Interested about MyCollegeCosts?</h6>
        <div>
          <button className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]">
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
};
const SeeResults = () => {
  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        See Results
      </h6>
      <p className="text-[#646464] font-light">
        Publishing a net price or scholarship calculator does not address
        cost-related concerns of the majority of students.  Since the Department
        of Education requires that you post your cost of attendance, most
        students will see how much your published costs are well before they
        receive a financial aid award.  We believe students want and need to
        know how much financial aid is necessary to meet their need well before
        most colleges make that information available to them.  MyCollegeCosts
        makes that possible.
        <br />
        <br />
        When students sign up for MyCollegeCosts they are able to integrate
        select information from their FSS, demographic information, and your
        published direct costs to determine how much financial aid they require
        to meet their financial aid.  With that information in hand, they are
        able to request that the institutions identified on their FAFSA meet the
        need gap remaining after all federal and state aid has been applied. 
        You may choose to meet that need using both funded and unfunded
        institutional aid.  If the need GAP is not met, the student has
        sufficient time to decide when, whether, and how to meet the costs for
        which they will be billed.
        <br />
        <br />
        But what might happen if you were to invite all of your student
        inquiries and applications to use MyCollegeCosts?  What if
        student/family concerns about cost were addressed much earlier in the
        decision-making cycle?
      </p>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">Interested about MyCollegeCosts?</h6>
        <div>
          <button className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]">
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
};
const Pricing = () => {
  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">Pricing</h6>
      <p className="text-[#646464] font-light">
        Are your reminders to submit ISIRs receiving mixed or minimal
        response? Why not use MyCollegeCosts to encourage students early in the
        decision-making process to take a closer look at your institution?
        <br />
        <br />
        MyCollegeCosts.ai will allow you to provide all or selected portions of
        your inquiry and applicant pools access to this tool at no charge to the
        student.  Your institution will be assessed a charge that varies as a
        function of the number of students you want to invite to be part of this
        process.
        <br />
        <br />
        There are no multiple year contracts and the invitation to participate
        comes directly from your institution. 
        <br />
        <br />
        Want to learn more? <br />
        <br />
        Simply complete our contact form and we will be in touch! 
      </p>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">Interested about MyCollegeCosts?</h6>
        <div>
          <button className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]">
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
};
export const YearCollege = () => {
  const [selectTabX1, setSelectTabX1] = useState(1);
  const [selectTabX2, setSelectTabX2] = useState(0);
  const [selectTabX3, setSelectTabX3] = useState(0);
  const [selectTabM1, setSelectTabM1] = useState(0);
  const [selectTabM2, setSelectTabM2] = useState(0);
  const [selectTabM3, setSelectTabM3] = useState(0);
  const [selectTabM4, setSelectTabM4] = useState(0);
  const [selectTabSm, setSelectTabSm] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);
  const [allExpanded, setAllExpanded] = useState(false);
  return (
    <>
      <div className="max-md:hidden grid gap-y-[40px] font-work_sans pb-[40px]">
        <section className="college_hero_bg w-100 md:h-[360px] xl:h-[500px] flex items-center justify-center relative">
          <div className="flex justify-center p-8 space-x-4 space-y-2 md:hero_md_caption xl:hero_caption">
            <div className="space-y-3">
              <div className="flex items-center space-x-4">
                <div>
                  <img src={college_logo} alt="" />
                </div>
                <h6 className="text-xl font-extrabold text-white">
                  MyCollegeCosts
                </h6>
              </div>
              <h6 className="text-[#fff] w-[550px] text-center font-poppins font-normal">
                A tool to help students receive the best possible financial aid
                award
              </h6>
            </div>
          </div>
        </section>
        <section className="flex items-center xl:justify-center md:flex-col-reverse xl:flex-row xl:space-x-8 md:px-3 font-opensans">
          <div className="flex items-center justify-center">
            <div className="border-shadow md:p-3 xl:p-9 md:w-[90vw] xl:w-[670px]  text-[#646464]">
              <div className="space-y-3">
                <h6 className="text-lg font-bold text-[#2B8B7A]">
                  College Professionals
                </h6>
                <div className="text-[#646464] xl:space-y-3 md:space-y-2">
                  <ItemLineBox title="Improve the quality of your conversations with students and family members!" />
                  <ItemLineBox title="Anticipate the impact of perceived affordability on the enrollment decisions of students." />
                  <ItemLineBox title="Reduce deposit withdrawals based upon cost." />
                  <ItemLineBox title="Increase the likelihood that you will meet your new student headcount and net tuition revenue goals." />
                </div>
                <div>
                  <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 rounded-lg mt-4">
                    <NavLink to={"/contact"}>
                      <span>Contact Us</span>
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center md:pb-8 xl:pb-0">
            <VideoBox
              url="https://youtu.be/7xf1VtgiB5A"
              className="md:w-[426px] xl:w-[547px] md:h-[273px] xl:h-[350px] rounded-lg"
            />
          </div>
        </section>
        <section>
          <div className="flex justify-between xl:w-[1440px] mx-auto max-xl:hidden">
            <CustomTab
              title="Student requirements"
              desc="Do your students meet these requirements?"
              onClick={() => {
                if (selectTabX1 === 1) setSelectTabX1(0);
                else setSelectTabX1(1);
              }}
              isChecked={selectTabX1 === 1}
            />
            <CustomTab
              title="How MyCollegeCosts helps students"
              desc="Learn about how MyCollegeCosts can help your students."
              onClick={() => {
                if (selectTabX1 === 2) setSelectTabX1(0);
                else setSelectTabX1(2);
              }}
              isChecked={selectTabX1 === 2}
            />
            <CustomTab
              title="College benefits"
              desc="Learn about how MyCollegeCosts can benefit your college."
              onClick={() => {
                if (selectTabX1 === 3) setSelectTabX1(0);
                else setSelectTabX1(3);
              }}
              isChecked={selectTabX1 === 3}
            />
          </div>
          {selectTabX1 === 0 ? null : (
            <div className="xl:w-[1440px] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 max-xl:hidden">
              {selectTabX1 === 1 ? <StudentRequirements /> : null}
              {selectTabX1 === 2 ? <HowMyCollegeCostsHelps /> : null}
              {selectTabX1 === 3 ? <CollegeBenefits /> : null}
            </div>
          )}

          <div className="flex justify-between xl:w-[1440px] mx-auto max-xl:hidden mt-8">
            <CustomTab
              title="Information students need"
              desc="Learn about Information when students need it."
              onClick={() => {
                if (selectTabX2 === 1) setSelectTabX2(0);
                else setSelectTabX2(1);
              }}
              isChecked={selectTabX2 === 1}
            />
            <CustomTab
              title="Making their case"
              desc="Learn about how students can make their case."
              onClick={() => {
                if (selectTabX2 === 2) setSelectTabX2(0);
                else setSelectTabX2(2);
              }}
              isChecked={selectTabX2 === 2}
            />
            <CustomTab
              title="See results"
              desc="Learn about how MyCollegeCosts can help make a difference."
              onClick={() => {
                if (selectTabX2 === 3) setSelectTabX2(0);
                else setSelectTabX2(3);
              }}
              isChecked={selectTabX2 === 3}
            />
          </div>
          {selectTabX2 === 0 ? null : (
            <div className="xl:w-[1440px] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 max-xl:hidden">
              {selectTabX2 === 1 ? <InformationStudentsNeed /> : null}
              {selectTabX2 === 2 ? <MakingTheirCase /> : null}
              {selectTabX2 === 3 ? <SeeResults /> : null}
            </div>
          )}

          <div className="flex justify-between xl:w-[1440px] mx-auto max-xl:hidden mt-8">
            <CustomTab
              title="Pricing"
              desc="Learn about the pricing when it comes to MyCollegeCosts."
              onClick={() => {
                if (selectTabX3 === 1) setSelectTabX3(0);
                else setSelectTabX3(1);
              }}
              isChecked={selectTabX3 === 1}
            />
          </div>
          {selectTabX3 === 0 ? null : (
            <div className="xl:w-[1440px] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 max-xl:hidden">
              <Pricing />
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3">
            <CustomTab
              title="Student requirements"
              desc="Do your students meet these requirements?"
              onClick={() => {
                if (selectTabM1 === 1) setSelectTabM1(0);
                else setSelectTabM1(1);
              }}
              isChecked={selectTabM1 === 1}
            />
            <CustomTab
              title="How MyCollegeCosts helps students"
              desc="Learn about how MyCollegeCosts can help your students."
              onClick={() => {
                if (selectTabM1 === 2) setSelectTabM1(0);
                else setSelectTabM1(2);
              }}
              isChecked={selectTabM1 === 2}
            />
          </div>
          {selectTabM1 === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              {selectTabM1 === 1 ? <StudentRequirements /> : null}
              {selectTabM1 === 2 ? <HowMyCollegeCostsHelps /> : null}
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3 mt-4">
            <CustomTab
              title="College benefits"
              desc="Learn about how MyCollegeCosts can benefit your college."
              onClick={() => {
                if (selectTabM2 === 1) setSelectTabM2(0);
                else setSelectTabM2(1);
              }}
              isChecked={selectTabM2 === 1}
            />
            <CustomTab
              title="Information students need"
              desc="Learn about Information when students need it."
              onClick={() => {
                if (selectTabM2 === 2) setSelectTabM2(0);
                else setSelectTabM2(2);
              }}
              isChecked={selectTabM2 === 2}
            />
          </div>
          {selectTabM2 === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              {selectTabM2 === 1 ? <CollegeBenefits /> : null}
              {selectTabM2 === 2 ? <InformationStudentsNeed /> : null}
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3 mt-4">
            <CustomTab
              title="Making their case"
              desc="Learn about how students can make their case."
              onClick={() => {
                if (selectTabM3 === 1) setSelectTabM3(0);
                else setSelectTabM3(1);
              }}
              isChecked={selectTabM3 === 1}
            />
            <CustomTab
              title="See results"
              desc="Learn about how MyCollegeCosts can help make a difference."
              onClick={() => {
                if (selectTabM3 === 2) setSelectTabM3(0);
                else setSelectTabM3(2);
              }}
              isChecked={selectTabM3 === 2}
            />
          </div>
          {selectTabM3 === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              {selectTabM3 === 1 ? <MakingTheirCase /> : null}
              {selectTabM3 === 2 ? <SeeResults /> : null}
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3 mt-4">
            <CustomTab
              title="Pricing"
              desc="Learn about the pricing when it comes to MyCollegeCosts."
              onClick={() => {
                if (selectTabM4 === 0) setSelectTabM4(0);
                else setSelectTabM4(1);
              }}
              isChecked={selectTabM4 === 1}
            />
          </div>
          {selectTabM4 === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              {selectTabM4 === 1 ? <Pricing /> : null}
            </div>
          )}
        </section>
        <section>
          <Carousel
            indicators={false}
            rightControl={<img src={arrow_right} alt="" />}
            leftControl={<img src={arrow_left} alt="" />}
            theme={{
              root: {
                base: "relative h-full xl:w-[1160px] md:w-[641px] max-md:slider_md_wd mx-auto max-md:rounded-xl ",
                leftControl:
                  "absolute -bottom-0 md:-bottom-[60px] right-2/4 flex h-full items-end justify-center pb-4 px-8 focus:outline-none",
                rightControl:
                  "absolute -bottom-0 md:-bottom-[60px] left-2/4 flex h-full items-end justify-center pb-4 px-8 focus:outline-none",
              },

              item: {
                base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
                wrapper: {
                  on: "md:w-full flex flex-shrink-0 transform cursor-grab snap-center justify-center md:mb-4",
                  off: "md:w-full flex flex-shrink-0 transform cursor-grab snap-center justify-center md:mb-4",
                },
              },
              scrollContainer: {
                base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth max-md:rounded-xl",
                snap: "snap-x",
              },
            }}
          >
            <ItemSrcBox
              src={gowen_photo}
              title="Director of Admissions Eastern University <br />Senior Admissions Consultant Miller Cook and Associates"
              name="Claire Gowan"
              desc="I have worked with the developers of MyCollegeCosts LLC for the past 12 years. During that time, I've watched them develop the model that presently has gone into the new MyCollegeCost student aid tool. Students can now auto package themselves and save the university weeks or even months to arrive at an affordable option. This new tool lets both parties win."
            />
            {/* <ItemSrcBox
              src={dana_photo}
              title="VPEM Bethany College WV."
              name="Dana Bearer"
              desc="Since 2022 I have worked with Miller Cook & Associates while they added to our enrollment and significantly reduced our tuition discount. The research that they conducted looked at the time it took institutions to respond to a student after filling, affordable packaging based on the time remaining till the next fall class started, factors influencing deposit withdrawals, and retention issues related to student aid packaging.  They partnered their findings with AI, and the new rollout of the federal simplified aid application to produce a student driven self-aid packaging option.  "
            /> */}
            <ItemSrcBox
              src={charles_photo}
              title="College President"
              name="Mr. Charles A. (Tony) McGeorge"
              desc="As a former college president, I am increasingly worried about the steep increase in the cost of a college education, making it difficult for many young Americans to afford. It is essential for us to find a solution to these soaring costs. One approach is to maximize student financial aid awards to ensure that college is affordable for all qualified students. I have found no better solution to maximize a student's award of financial assistance, so I have joined the MyCollegeCosts team. "
            />
          </Carousel>
        </section>
        <div className="p-8 my-auto min-h-[600px] xl:w-[1080px] w-full mx-auto font-opensans">
          <section className="xl:p-20 space-y-4">
            <div className="flex justify-between">
              <h5 className="text-[40px] text-[#2B574F] font-semibold">
                FAQ
              </h5>
              <div className="flex items-center">
                <div className="flex space-x-4">
                  <h6
                    className="text-[#2B574F] font-semibold cursor-pointer"
                    onClick={() => {
                      setAllExpanded(true);
                    }}
                  >
                    Expand all
                  </h6>
                  <div className="bg-[#646464] opacity-30 w-[2px]" />
                  <h6
                    className="text-[#2B574F] font-semibold cursor-pointer"
                    onClick={() => {
                      setAllExpanded(false);
                    }}
                  >
                    Collapse all
                  </h6>
                </div>
              </div>
            </div>
            <h6 className="text-[#3FC6AD] font-bold">
              How can MyCollegeCosts Benefit My Institution?
            </h6>
            <div className="bg-[#F3F5F6] rounded shadow-lg space-y-5 p-8">
              <QItem
                title="Package All Of Your ISIR Submitters"
                description="Most financial aid offices will not provide new students estimated financial aid packages until they have been accepted for admission.  This means you may be ignoring up to 1/3 of your ISIR submissions! <br /> <br /> MyCollegeCosts allows you to provide estimated packages to student inquiries and applicants while they are still considering your institution without adding additional staff to your financial aid office. "
                isExpanded={selectedItem === 1 || allExpanded}
                onClick={() => {
                  if (selectedItem === 1) setSelectedItem(0);
                  else setSelectedItem(1);
                }}
              />
              <QItem
                title="Provide Estimated Awards When Student Need Them"
                description="Your student aid office is gearing up to handle thousands of ISIRs from new and returning students. It will take weeks, if not months, to start the awarding process after testing your software and systems.  <br /><br />
                Unfortunately, your timeline for packaging may be far too late.  Early ISIR submitters are often shopping for colleges.  These students want to know whether or not they can afford to attend your institution BEFORE they will complete the application process.  Rather than motivating students to complete the application for admission by withholding aid, you may be encouraging them to withdraw your institution from consideration."
                isExpanded={selectedItem === 2 || allExpanded}
                onClick={() => {
                  if (selectedItem === 2) setSelectedItem(0);
                  else setSelectedItem(2);
                }}
              />
              <QItem
                title="Improve Turn Around Time"
                description="Once the student receives the FSS (Financial Aid Summary) and shares that information with My College Costs, that student can share findings and recommendations to you within five days."
                isExpanded={selectedItem === 3}
                onClick={() => {
                  if (selectedItem === 3) setSelectedItem(0);
                  else setSelectedItem(3);
                }}
              />
              <QItem
                title="Know How Much Aid It Will Take To Enroll A Student"
                description="The student will share with you the total amount of aid for which they may be eligible, the amount of money they can afford to pay for college and the direct cost of attending your institution.  The difference between the amount of aid they expect to receive and the amount they can afford to pay MINUS your annual direct costs represents the amount of additional aid they will need in order to attend. <br/> <br/> You may choose to award the total amount of aid they need or award some portion of it.  The decision is yours.  You will send an estimated award letter to the student which will allow them to make an enrollment decision based upon affordability."
                isExpanded={selectedItem === 4 || allExpanded}
                onClick={() => {
                  if (selectedItem === 4) setSelectedItem(0);
                  else setSelectedItem(4);
                }}
              />
              <QItem
                title="Who can participate in MyCollegeCosts?"
                description="You can make MyCollegeCosts available to your entire 2025 UG Candidate pool. With the purchase of an institutional license to use MyCollegeCosts you will be able to provide access to this important tool FREE OF CHARGE to all or some portion of your candidate pool. "
                isExpanded={selectedItem === 5 || allExpanded}
                onClick={() => {
                  if (selectedItem === 5) setSelectedItem(0);
                  else setSelectedItem(5);
                }}
              />
              <QItem
                title="Can I segment my candidate pool and offer MyCollegeCosts only to selected students?"
                description="Yes, you can identify the cohort groups of students you want to include. For example, you may want to select only those students who have expressed interest in your under enrolled majors. You can create any subgroup option that you can think of - new territories, alumni recommendations, etc."
                isExpanded={selectedItem === 6 || allExpanded}
                onClick={() => {
                  if (selectedItem === 6) setSelectedItem(0);
                  else setSelectedItem(6);
                }}
              />
              <QItem
                title="Must students submit their ISIR to my institution in order to use MyCollegeCosts?"
                description="Initially, no.  But in order to receive an official financial aid package from your institution they will be required to do so. One of the many benefits of MyCollegeCosts is that it motivates students who might not otherwise submit an ISIR or complete their application for admission to do so.  Once they know that your institution is, in fact, an affordable option for them they will want to learn more about you!"
                isExpanded={selectedItem === 7 || allExpanded}
                onClick={() => {
                  if (selectedItem === 7) setSelectedItem(0);
                  else setSelectedItem(7);
                }}
              />
            </div>
          </section>
        </div>
      </div>
      <div className="grid px-4 py-4 md:hidden gap-y-4 font-work_sans">
        <section className="flex flex-col items-center space-y-4 font-opensans">
          <div className="flex items-center justify-center">
            <VideoBox
              url="https://youtu.be/7xf1VtgiB5A"
              className="md:w-[426px] xl:w-[547px] md:h-[273px] xl:h-[350px] rounded-lg"
            />
          </div>
          <div className="flex items-center justify-center">
            <div className="border-shadow md:p-3 p-6 md:w-[90vw] xl:w-[670px]  text-[#646464]">
              <div className="space-y-3">
                <h6 className="text-lg font-bold text-[#2B8B7A]">
                  College Professionals
                </h6>
                <div className="text-[#646464] xl:space-y-3 md:space-y-2">
                  <ItemLineBox title="Improve the quality of your conversations with students and family members!" />
                  <ItemLineBox title="Anticipate the impact of perceived affordability on the enrollment decisions of students." />
                  <ItemLineBox title="Reduce deposit withdrawals based upon cost." />
                  <ItemLineBox title="Increase the likelihood that you will meet your new student headcount and net tuition revenue goals." />
                </div>
                <div>
                  <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 rounded-lg mt-4">
                    <NavLink to={"/contact"}>
                      <span>Contact Us</span>
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="space-y-4">
          <CustomTab
            title="Student requirements"
            desc="Do your students meet these requirements?"
            onClick={() => {
              if (selectTabSm === 1) setSelectTabSm(0);
              else setSelectTabSm(1);
            }}
            isChecked={selectTabSm === 1}
          />
          {selectTabSm === 1 ? <StudentRequirements /> : null}
          <CustomTab
            title="How MyCollegeCosts helps students"
            desc="Learn about how MyCollegeCosts can help your students."
            onClick={() => {
              if (selectTabSm === 2) setSelectTabSm(0);
              else setSelectTabSm(2);
            }}
            isChecked={selectTabSm === 2}
          />
          {selectTabSm === 2 ? <HowMyCollegeCostsHelps /> : null}
          <CustomTab
            title="College benefits"
            desc="Learn about how MyCollegeCosts can benefit your college."
            onClick={() => {
              if (selectTabSm === 3) setSelectTabSm(0);
              else setSelectTabSm(3);
            }}
            isChecked={selectTabSm === 3}
          />
          {selectTabSm === 3 ? <CollegeBenefits /> : null}
          <CustomTab
            title="Information students need"
            desc="Learn about Information when students need it."
            onClick={() => {
              if (selectTabSm === 4) setSelectTabSm(0);
              else setSelectTabSm(4);
            }}
            isChecked={selectTabSm === 4}
          />
          {selectTabSm === 4 ? <InformationStudentsNeed /> : null}

          <CustomTab
            title="Making their case"
            desc="Learn about how students can make their case."
            onClick={() => {
              if (selectTabSm === 5) setSelectTabSm(0);
              else setSelectTabSm(5);
            }}
            isChecked={selectTabSm === 5}
          />
          {selectTabSm === 5 ? <InformationStudentsNeed /> : null}

          <CustomTab
            title="See results"
            desc="Learn about how MyCollegeCosts can help make a difference."
            onClick={() => {
              if (selectTabSm === 6) setSelectTabSm(0);
              else setSelectTabSm(6);
            }}
            isChecked={selectTabSm === 6}
          />
          {selectTabSm === 6 ? <SeeResults /> : null}

          <CustomTab
            title="Pricing"
            desc="Learn about the pricing when it comes to MyCollegeCosts."
            onClick={() => {
              if (selectTabSm === 7) setSelectTabSm(0);
              else setSelectTabSm(7);
            }}
            isChecked={selectTabSm === 7}
          />
          {selectTabSm === 7 ? <Pricing /> : null}
        </section>
        <section className="-mx-4 bg-[#3FC6AD]">
          <Carousel
            indicators={false}
            rightControl={<img src={arrow_right} alt="" />}
            leftControl={<img src={arrow_left} alt="" />}
            theme={{
              root: {
                base: "relative h-full xl:w-[1160px] md:w-[641px] max-md:w-[90vw] mx-auto max-md:rounded-xl max-md:py-4",
                leftControl:
                  "absolute bottom-3 md:-bottom-[60px] right-2/4 flex h-full items-end justify-center pb-4 px-8 focus:outline-none",
                rightControl:
                  "absolute bottom-3 md:-bottom-[60px] left-2/4 flex h-full items-end justify-center pb-4 px-8 focus:outline-none",
              },

              item: {
                base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
                wrapper: {
                  on: "md:w-full flex flex-shrink-0 transform cursor-grab snap-center justify-center md:mb-4 max-md:mx-2",
                  off: "md:w-full flex flex-shrink-0 transform cursor-grab snap-center justify-center md:mb-4 max-md:mx-2",
                },
              },
              scrollContainer: {
                base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth max-md:rounded-xl",
                snap: "snap-x",
              },
            }}
          >
            <ItemSrcBox
              src={gowen_photo}
              title="Director of Admissions Eastern University <br />Senior Admissions Consultant Miller Cook and Associates"
              name="Claire Gowan"
              desc="I have worked with the developers of MyCollegeCosts LLC for the past 12 years. During that time, I've watched them develop the model that presently has gone into the new MyCollegeCost student aid tool. Students can now auto package themselves and save the university weeks or even months to arrive at an affordable option. This new tool lets both parties win."
            />
            {/* <ItemSrcBox
              src={dana_photo}
              title="VPEM Bethany College WV."
              name="Dana Bearer"
              desc="Since 2022 I have worked with Miller Cook & Associates while they added to our enrollment and significantly reduced our tuition discount. The research that they conducted looked at the time it took institutions to respond to a student after filling, affordable packaging based on the time remaining till the next fall class started, factors influencing deposit withdrawals, and retention issues related to student aid packaging.  They partnered their findings with AI, and the new rollout of the federal simplified aid application to produce a student driven self-aid packaging option.  "
            /> */}
            <ItemSrcBox
              src={charles_photo}
              title="College President"
              name="Mr. Charles A. (Tony) McGeorge"
              desc="As a former college president, I am increasingly worried about the steep increase in the cost of a college education, making it difficult for many young Americans to afford. It is essential for us to find a solution to these soaring costs. One approach is to maximize student financial aid awards to ensure that college is affordable for all qualified students. I have found no better solution to maximize a student's award of financial assistance, so I have joined the MyCollegeCosts team. "
            />
          </Carousel>
        </section>
        <div className="p-4 my-auto xl:faq_bg_decoration min-h-[600px] xl:w-[1080px] w-full mx-auto font-opensans">
          <section className="xl:p-20 space-y-4 xl:w-[780px]">
            <div className="flex justify-between">
              <h5 className="xl:text-[40px] text-[#2B574F] font-semibold">
                FAQ
              </h5>
              <div className="flex items-center">
                <div className="flex space-x-4">
                  <h6
                    className="text-[#2B574F] font-semibold cursor-pointer"
                    onClick={() => {
                      setAllExpanded(true);
                    }}
                  >
                    Expand all
                  </h6>
                  <div className="bg-[#646464] opacity-30 w-[2px]" />
                  <h6
                    className="text-[#2B574F] font-semibold cursor-pointer"
                    onClick={() => {
                      setAllExpanded(false);
                    }}
                  >
                    Collapse all
                  </h6>
                </div>
              </div>
            </div>
            <h6 className="text-[#3FC6AD] font-bold">
              How can MyCollegeCosts Benefit My Institution?
            </h6>
            <div className="bg-[#F3F5F6] rounded shadow-lg space-y-5 p-8">
              <QItem
                title="Package All Of Your ISIR Submitters"
                description="Most financial aid offices will not provide new students estimated financial aid packages until they have been accepted for admission.  This means you may be ignoring up to 1/3 of your ISIR submissions! <br /> <br /> MyCollegeCosts allows you to provide estimated packages to student inquiries and applicants while they are still considering your institution without adding additional staff to your financial aid office. "
                isExpanded={selectedItem === 1 || allExpanded}
                onClick={() => {
                  if (selectedItem === 1) setSelectedItem(0);
                  else setSelectedItem(1);
                }}
              />
              <QItem
                title="Provide Estimated Awards When Student Need Them"
                description="Your student aid office is gearing up to handle thousands of ISIRs from new and returning students. It will take weeks, if not months, to start the awarding process after testing your software and systems.  <br /><br />
                Unfortunately, your timeline for packaging may be far too late.  Early ISIR submitters are often shopping for colleges.  These students want to know whether or not they can afford to attend your institution BEFORE they will complete the application process.  Rather than motivating students to complete the application for admission by withholding aid, you may be encouraging them to withdraw your institution from consideration."
                isExpanded={selectedItem === 2 || allExpanded}
                onClick={() => {
                  if (selectedItem === 2) setSelectedItem(0);
                  else setSelectedItem(2);
                }}
              />
              <QItem
                title="Improve Turn Around Time"
                description="Once the student receives the FSS (Financial Aid Summary) and shares that information with My College Costs, that student can share findings and recommendations to you within five days."
                isExpanded={selectedItem === 3}
                onClick={() => {
                  if (selectedItem === 3) setSelectedItem(0);
                  else setSelectedItem(3);
                }}
              />
              <QItem
                title="Know How Much Aid It Will Take To Enroll A Student"
                description="The student will share with you the total amount of aid for which they may be eligible, the amount of money they can afford to pay for college and the direct cost of attending your institution.  The difference between the amount of aid they expect to receive and the amount they can afford to pay MINUS your annual direct costs represents the amount of additional aid they will need in order to attend. <br/> <br/> You may choose to award the total amount of aid they need or award some portion of it.  The decision is yours.  You will send an estimated award letter to the student which will allow them to make an enrollment decision based upon affordability."
                isExpanded={selectedItem === 4 || allExpanded}
                onClick={() => {
                  if (selectedItem === 4) setSelectedItem(0);
                  else setSelectedItem(4);
                }}
              />
              <QItem
                title="Who can participate in MyCollegeCosts?"
                description="You can make MyCollegeCosts available to your entire 2025 UG Candidate pool. With the purchase of an institutional license to use MyCollegeCosts you will be able to provide access to this important tool FREE OF CHARGE to all or some portion of your candidate pool. "
                isExpanded={selectedItem === 5 || allExpanded}
                onClick={() => {
                  if (selectedItem === 5) setSelectedItem(0);
                  else setSelectedItem(5);
                }}
              />
              <QItem
                title="Can I segment my candidate pool and offer MyCollegeCosts only to selected students?"
                description="Yes, you can identify the cohort groups of students you want to include. For example, you may want to select only those students who have expressed interest in your under enrolled majors. You can create any subgroup option that you can think of - new territories, alumni recommendations, etc."
                isExpanded={selectedItem === 6 || allExpanded}
                onClick={() => {
                  if (selectedItem === 6) setSelectedItem(0);
                  else setSelectedItem(6);
                }}
              />
              <QItem
                title="Must students submit their ISIR to my institution in order to use MyCollegeCosts?"
                description="Initially, no.  But in order to receive an official financial aid package from your institution they will be required to do so. One of the many benefits of MyCollegeCosts is that it motivates students who might not otherwise submit an ISIR or complete their application for admission to do so.  Once they know that your institution is, in fact, an affordable option for them they will want to learn more about you!"
                isExpanded={selectedItem === 7 || allExpanded}
                onClick={() => {
                  if (selectedItem === 7) setSelectedItem(0);
                  else setSelectedItem(7);
                }}
              />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
